import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    formatclass: {
      type: String,
      "default": "black--text" // this isn't right, dependent upon darkmode
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    celltype: {
      type: String,
      "default": ""
    },
    showturns: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode;
    var createPercentFormatter = function createPercentFormatter(pctDecimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: pctDecimals,
        maximumFractionDigits: pctDecimals
      });
    };
    var formatPercent = createPercentFormatter(1);
    return {
      formatPercent: formatPercent,
      refCode: refCode
    };
  },
  computed: {
    cellValue: function cellValue() {
      // data is an object where v is a number because of the calculation performed on the number
      // (data.v straight from the database is a string)
      var data = this.dataObj;
      var type = this.celltype;
      var currencyToggle = this.currencytoggle;
      if (data !== null && data !== void 0 && data.v) {
        var pct = data.v;
        if (pct === "0.000000") {
          return;
        }
        if (typeof pct === "string") {
          pct = parseFloat(pct);
        }
        if (type === "pct") {
          pct = pct / 100;
        }
        if (type === "dxdt" && currencyToggle === 1) {
          if (data.vex) {
            pct = data.vex;
          } else {
            return;
          }
        }
        return pct > 0 ? "".concat(this.formatPercent.format(pct)) : "(".concat(this.formatPercent.format(Math.abs(pct)), ")");
      }
      return "";
    }
  }
});